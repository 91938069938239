<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { required } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
      'Transfer': 'Transferir',
      'From wallet': 'Da carteira',
      'From wallet is required.': 'A carteira é obrigatória.',
      'Wallet balance': 'Saldo da carteira',
      'To wallet': 'Para carteira',
      'To wallet is required.': 'A carteira é obrigatória.',
      'Username to': 'Para o usuário',
      'Username is required.': 'O usuário é obrigatório',
      'Transfer amount': 'Valor da transferência',
      'Amount is required.': 'O valor é obrigatório',

      'The wallet is invalid.': 'A carteira é inválida.',
      'The username was not found.': 'O usuário não foi encontrado.',
      'Enter a transfer amount.': 'Digite o valor da transferência.',
      'The minimum transfer amount is R$ 0,50.': 'O valor mínimo para transferência é de R$ 10,00.',
      'You do not have enough balance to transfer.': 'Você não possui saldo disponível para transferência.',
      'Your transfer was successful.': 'Sua transferência foi realizada com sucesso.',
    },
    es: {
      'Transfer': 'Transferir',
      'From wallet': 'De la cartera',
      'From wallet is required.': 'La cartera se requiere.',
      'Wallet balance': 'Saldo de la cartera',
      'To wallet': 'Para la cartera',
      'To wallet is required.': 'La cartera se requiere.',
      'Username to': 'Para o usuario',
      'Username is required.': 'Nombre de usuario es requerido.',
      'Transfer amount': 'Valor da transferencia',
      'Amount is required.': 'El valor se requiere.',

      'The wallet is invalid.': 'La billetera no es válida.',
      'The username was not found.': 'No se encontró el nombre de usuario.',
      'Enter a transfer amount.': 'Introducir una cantidad de transferencia.',
      'The minimum transfer amount is R$ 0,50.': 'La cantidad mínima de transferencia es de R $ 10,00.',
      'You do not have enough balance to transfer.': 'Usted no tiene saldo suficiente para transferir.',
      'Your transfer was successful.': 'Su transferencia se ha realizado correctamente.',
    }
  },
  components: {
    Layout
  },

  data() {
    return {
      loading: false,
      success: false,

      wallet: {
        from: [],
        to: []
      },
      from: '',
      balance: 'R$ -,--',
      to: '',
      username: this.$store.state.account.user.username,
      value: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
      alert: { type: '', message: '' },
    };
  },
  directives: { money: VMoney },
  validations: {
    from: { required },
    to: { required },
    username: { required },
    value: { required },
  },
  methods: {
    getTransfer() {
      api
        .get('wallet/transfer')
        .then(response => {
          if (response.data.status=='success') {
            this.wallet.from = response.data.from
            this.from = 'cash'
            this.wallet.to = response.data.to
            this.to = 'bonus'
          }
        })
    },
    getBalance(wallet) {
      this.balance = '-- -,--'
      api
        .get('wallet/balance/' + wallet)
        .then(response => {
          if (response.data.status=='success') {
            this.balance = response.data.balance
          }
        })
    },
    transterSubmit() {
      this.loading = true
      this.$v.$touch();

      if (this.value) {
        api
          .post('wallet/transfer', {
            from: this.from,
            to: this.to,
            username: this.username,
            value: this.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.success = true
              this.username = ''
              this.value = '0,00'
              this.$v.$reset()

              this.alert.type = 'alert-success'
              this.alert.message = response.data.message
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
            }

            this.loading = false
          })
      }
    }
  },
  mounted() {
    this.getTransfer()
  },
  watch: {
    from(wallet) {
      if (wallet) {
        this.getBalance(wallet)
      } else {
        this.balance = 'R$ -,--'
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Transfer') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="success" class="text-center-sm">
              {{ t('Your transfer was successful.') }}
            </div>
            <b-form v-else class="p-3" @submit.prevent="transterSubmit">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <b-form-group id="from" :label="t('From wallet')" label-for="from">
                <select id="from" v-model="from" class="custom-select" :class="{ 'is-invalid': $v.from.$error }">
                  <option v-for="(option,index) in wallet.from" :key="index" :value="index">{{ t(option) }}</option>
                </select>
                <div v-if="$v.from.$error" class="invalid-feedback">
                  <span v-if="!$v.from.required">{{ t('From wallet is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="balance" :label="t('Wallet balance')" label-for="balance">
                <b-form-input id="balance" v-model="balance" type="text" disabled></b-form-input>
              </b-form-group>
              <b-form-group id="to" :label="t('To wallet')" label-for="to">
                <select id="to" v-model="to" class="custom-select" :class="{ 'is-invalid': $v.to.$error }">
                  <option v-for="(option,index) in wallet.to" :key="index" :value="index">{{ t(option) }}</option>
                </select>
                <div v-if="$v.to.$error" class="invalid-feedback">
                  <span v-if="!$v.to.required">{{ t('To wallet is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="username" :label="t('Username to')" label-for="username">
                <b-form-input id="username" v-model="username" type="text" :class="{ 'is-invalid': $v.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"></b-form-input>
                <div v-if="$v.username.$error" class="invalid-feedback">
                  <span v-if="!$v.username.required">{{ t('Username is required.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="value" :label="t('Transfer amount')" label-for="value">
                <b-form-input id="value" v-model="value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.value.$error }"></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{ t('Amount is required.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="loading == true || this.value==='R$ 0,00'" type="submit" variant="danger">
                  {{ t('Transfer') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>